@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

html {
    width: 100%;
    overflow-x: hidden;


    body.oh {
        overflow: hidden;
    }
}


ul#myList {
    width: 100%;
    height: 8rem;
    background-color: rgb(104, 104, 104);
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    li {
        list-style: none;

        a {
            color: #ffffff;
            text-decoration: none;
            font-size: 3rem;
            transition: .3s;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;

            &:hover {
                color: black;
            }
        }
    }
}


h1 {
    font-size: 5rem;
}



.container {
    max-width: 140rem;
    margin: 0 auto;
}

@media (max-width: 1140px) {

    .container {
        width: 100% !important;
        padding: 0 5% !important;
    }

}

.content {
    min-height: 90vh;
}

.body_height {
    min-height: 40vh;
    padding-top: 12rem !important;
}

.admin_main_body {
    display: block;
    padding-top: 12rem;
    padding-left: 22rem;
    padding-right: 3rem;




    .add_btn_common {
        background-color: rgb(0, 98, 159);
        text-decoration: none;
        padding: 1rem 2rem;
        color: #ffffff;
        font-size: 2.5rem;
        font-weight: 500;
        transition: .2s;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -ms-transition: .2s;
        -o-transition: .2s;
        display: inline-block;

        &:hover {
            background-color: rgb(3, 82, 131);
        }
    }

    .delete_btn_common {
        background-color: rgb(255, 52, 52);
        text-decoration: none;
        padding: 1rem 2rem;
        color: #ffffff;
        font-size: 2.5rem;
        font-weight: 500;
        transition: .2s;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -ms-transition: .2s;
        -o-transition: .2s;
        display: inline-block;
        outline: none;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: rgb(216, 19, 19);
        }
    }

    .btn_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .admin_form {
        width: 40%;

        select {
            display: block;
            margin-bottom: 2rem;
            width: 100%;
            height: 5rem;
            outline: none;
            border: .1rem solid rgba(0, 98, 159, 0.4);
            font-size: 2.5rem;
            font-weight: 400;
            color: rgba(1, 50, 80, 0.5);
            padding: .5rem 0;


        }

        input {
            display: block;
            margin-bottom: 2rem;
            font-size: 2.5rem;
            font-weight: 400;
            padding: 1rem;
            outline: none;
            border: .1rem solid rgba(0, 98, 159, 0.4);
            color: rgb(0, 98, 159);
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;
            width: 100%;

            &:focus {
                outline: none;
                border: .1rem solid rgba(0, 98, 159, 1);

            }
        }

        input::placeholder {
            color: rgba(1, 50, 80, 0.5);
        }

        input[type="file"] {
            display: none;
        }

        label {
            background-color: rgb(0, 98, 159);
            font-size: 2.5rem;
            color: #ffffff;
            font-weight: 500;
            cursor: pointer;
            padding: 1rem 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;

            &:hover {
                background-color: rgb(3, 82, 131);
            }
        }

        .image_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;

            img {
                width: 29rem;
                margin-left: 3rem;
            }
        }

        button {
            background-color: rgb(0, 98, 159);
            font-size: 2.5rem;
            color: #ffffff;
            font-weight: 500;
            cursor: pointer;
            padding: 1rem 2rem;
            display: block;
            justify-content: center;
            align-items: center;
            outline: none;
            border: none;
            width: 100%;
            margin-top: 2.5rem;
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;

            &:hover {
                background-color: rgb(3, 82, 131);
            }
        }
    }

}

.album_edit_button{
    margin-left: 3rem;
}

.noDataExists{
    text-align: center;
    font-size: 2.3rem;
    color: #00619F;
}



.read_board{
    width: 40%;
    color: rgb(3, 82, 131);
    font-size: 2.5rem;
    line-height: 1.7;

    p{
        background-color: rgba(3, 82, 131, 0.05);
        padding: 2rem;

    }
}


table{
    width: 100%;



    thead {
        background-color: rgba(0, 60, 97, 0.05);
        color: rgb(0, 60, 97);
        font-size: 3rem;

        th {
            padding: 1rem;
        }

    }


    tbody {
        font-size: 2.5rem;
        color: rgba(0, 60, 97, 0.7);

        td {
            text-align: center;

            button {
                display: inline-block;
                margin: 1rem;
                padding: 1rem 2rem;
                font-size: 2.5rem;
                outline: none;
                border: none;
                color: #ffffff;
                transition: .2s;
                -webkit-transition: .2s;
                -moz-transition: .2s;
                -ms-transition: .2s;
                -o-transition: .2s;
                cursor: pointer;
                background-color: rgb(231, 41, 41);

                
                &:hover{
                    background-color: rgb(202, 17, 17);

                }
            }

            a {
                display: inline-block;
                margin: 1rem;
                padding: 1rem 2rem;
                font-size: 2.5rem;
                outline: none;
                border: none;
                color: #ffffff;
                transition: .2s;
                -webkit-transition: .2s;
                -moz-transition: .2s;
                -ms-transition: .2s;
                -o-transition: .2s;
                cursor: pointer;
                background-color: rgba(0, 60, 97, 0.884);
                text-decoration: none;

                &:hover{
                    background-color: rgba(1, 40, 65, 0.884);

                }

            }


            img {
                width: 10rem;
                padding: 2rem;
            }
        }
    }

}