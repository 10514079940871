#footer {
    margin-top: 10rem;
    position: relative;

    #arrowToTop {
        position: absolute;
        bottom: 10rem;
        right: 10rem;
        height: 5rem;
        width: 5rem;
        background-color: rgba(0, 98, 159, 0);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        transition: .3s;
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -ms-transition: .3s;
        -o-transition: .3s;

        svg {
            transition: .3s;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;
            fill: #00619F;
        }

        &:hover {
            background-color: rgb(0, 98, 159);

            svg {
                fill: #ffffff;
            }
        }

    }

    .top {
        background-color: rgba(0, 0, 0, 0.05);
        padding: 6rem 0;

        .main {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            h2 {
                font-size: 2.8rem;
                color: rgba(0, 0, 0, 0.8);
                text-align: left;
                margin-bottom: 5rem;
            }


            .left {
                .footerLogo {
                    width: 12rem;
                    display: block;
                    margin-bottom: 4.5rem;
                }

                p.shortDesc {
                    max-width: 34rem;
                    font-size: 1.8rem;
                    color: rgba(0, 0, 0, 0.7);
                    line-height: 1.4;
                    text-align: left !important;
                }
            }

            .contact {
                width: 35rem;
                text-align: left !important;

                .address {
                    width: 100%;
                    display: flex;
                    margin-bottom: 1rem;

                    img {
                        display: inline-block;
                        margin-right: 1rem;
                        margin-top: .5rem;
                    }

                    p {
                        font-size: 1.8rem;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }

                .phone {
                    width: 100%;
                    display: flex;
                    margin-bottom: 1rem;

                    img {
                        display: inline-block;
                        margin-right: 1rem;
                        margin-top: 0.5rem;
                    }

                    p {
                        font-size: 1.8rem;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }

                .email {
                    width: 100%;
                    display: flex;
                    margin-bottom: 1rem;

                    img {
                        display: inline-block;
                        margin-right: 1rem;
                        margin-top: .6rem;
                    }

                    p {
                        font-size: 1.8rem;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }
            }

            .links {
                width: 15rem;
                text-align: left !important;

                .main {
                    width: 100%;
                    display: flex;
                    justify-content: left;

                    ul {
                        list-style: none;

                        li {
                            margin-bottom: 0.5rem;

                            a {
                                text-decoration: none;
                                font-size: 1.8rem;
                                color: rgba(0, 0, 0, 0.7);
                                transition: .3s;
                                -webkit-transition: .3s;
                                -moz-transition: .3s;
                                -ms-transition: .3s;
                                -o-transition: .3s;

                                &:hover {
                                    color: rgb(0, 98, 159);
                                }
                            }
                        }
                    }

                }
            }

            .services {
                width: 16rem;
                text-align: left !important;



                ul {
                    list-style: none;

                    li {
                        margin-bottom: 0.5rem;
                        font-size: 1.8rem;
                        color: rgba(0, 0, 0, 0.7);

                    }
                }
            }


        }

        .social {
            width: 25rem;
            display: flex;
            margin-top: 3rem;
            justify-content: space-between;

            a {
                height: auto;
                width: 22.4rem;
                display: inline-block;
                margin-right: 1rem;

                img {
                    width: 100%;
                    opacity: .5;
                    transition: .3s;
                    -webkit-transition: .3s;
                    -moz-transition: .3s;
                    -ms-transition: .3s;
                    -o-transition: .3s;
                }

                svg {
                    fill: rgba(0, 0, 0, 0.3);
                    transition: .3s;
                    -webkit-transition: .3s;
                    -moz-transition: .3s;
                    -ms-transition: .3s;
                    -o-transition: .3s;
                }

                &:hover {
                    img {
                        opacity: 1;
                    }

                    svg {
                        fill: #00609D;
                        transition: .3s;
                        -webkit-transition: .3s;
                        -moz-transition: .3s;
                        -ms-transition: .3s;
                        -o-transition: .3s;
                    }
                }
            }
        }
    }

    .bottom {
        background-color: #00619F;
        color: #ffffff;
        font-size: 1.6rem;
        padding: 2.5rem 0;

        .main {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}

@media (max-width:768px) {
    #footer {
        #arrowToTop {
            right: 1rem;
            bottom: 6rem;
        }

        .top {
            .main {

                .services,
                .links {
                    margin-top: 4rem;
                }

                .services {
                    margin-right: 8rem;
                }
            }
        }
    }
}

@media (max-width:425px) {
    #footer {
        .top {
            .main {
                justify-content: center;

                h2 {
                    text-align: center;
                    margin-bottom: 2.5rem;
                }

                .left {
                    text-align: center;

                    img {
                        margin: 0 auto;
                        margin-bottom: 3rem;
                    }
                }

                .services {
                    margin-right: 0;
                }

                .contact {
                    margin-top: 3rem;
                }

                .links {
                    width: 75%;

                }
            }

            .social {
                width: 100% !important;
                display: flex;
                justify-content: center;

                a {
                    width: 5rem;
                }
            }
        }


        .bottom {
            background-color: #00619F;
            color: #ffffff;
            font-size: 1.2rem;
            padding: 1.5rem 0;

            .main {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}