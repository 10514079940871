#header {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    padding: 12rem 0;
    height: 70rem;

    main {
        position: relative;
        left: 0%;
        animation: anm5 1.5s linear;
        -webkit-animation: anm5 1.5s linear;
        opacity: 1;
        z-index: 2;

        @keyframes anm5 {
            0% {
                left: -100%;
                opacity: 0;
            }

            100% {
                left: 0%;
                opacity: 1;
            }

        }
    }

    .bg_slider{
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 0;
    }


    h2 {
        margin-bottom: 6.5rem;
        color: rgba(0, 0, 0, 0.7);
        font-size: 2.8rem;
        font-weight: 400;
        text-align: left;
    }

    h1 {
        max-width: 75.5rem;
        font-size: 6.2rem;
        font-weight: 500;
        color: #00629F;
        margin-bottom: 6rem;
        line-height: 1.4;
        text-align: left;
    }

    p {
        max-width: 55.5rem;
        color: rgba(0, 0, 0, 0.7);
        font-size: 2.8rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;

    }


}

@media (max-width: 425px) {

    #header {
        padding: 6rem;
        padding-top: 10rem;
        position: relative;
        height: 37rem;

        .bg_slider{
           top: 10rem;
           height: 20rem;
        }



        h2 {
            margin-bottom: 4rem;
            font-size: 2rem;
            text-align: center;
        }

        h1 {
            max-width: 75.5rem;
            font-size: 4rem;
            margin-bottom: 4rem;
            line-height: 1.4;
            text-align: center;
        }

        p {
            max-width: 55.5rem;
            font-size: 2rem;
            line-height: 1.5;
            text-align: center;

        }
    }
}

@media (max-width: 320px) {
    #header {
        padding: 6rem 0;
        position: relative;


    }

}