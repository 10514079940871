#exportAndImport {
    display: block;

    .main_body {
        padding: 10rem 0;

        h2 {
            color: #00609D;
            font-size: 5rem;
            margin-bottom: 3rem;
            text-transform: uppercase;
            text-align: left;
        }

        section {
            margin-bottom: 10rem;

            img {
                width: 100%;
            }

            img#desktop {
                margin-top: 7rem;
            }
            img#moblie {
                padding: 0 15%;
                display: none;
            }

            p {
                max-width: 116rem;
                font-size: 2.5rem;
                color: rgba(0, 0, 0, 0.7);
                line-height: 1.6;
            }
        }

        section#sectors {
            width: 100%;

            .boxWrapper {
                margin-top: 9rem;
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .singleBox {
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: 50%;
                        height: auto;
                        margin-bottom: 8rem;
                    }

                    p {
                        font-size: 2.2rem;
                        color: rgba(0, 0, 0, 0.7);
                        text-align: left;

                    }

                }
            }
        }


        .setpWrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .singleStep {
                width: 31%;
                display: flex;
                flex-direction: column;
                align-items: center;


                .circle {
                    width: 24rem;
                    height: 24rem;
                    background-color: #00609D;
                    border-radius: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    -ms-border-radius: 100%;
                    -o-border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 7rem;
                    color: #FFF200;
                    position: relative;
                    z-index: 9999;
                }


                .bottom {
                    width: 100%;
                    border: .5rem solid #00609D;
                    padding: 16rem 1rem 6rem 1rem;
                    margin-top: -12rem;
                    position: relative;
                    z-index: 999;
                    display: flex;
                    flex-direction: column;
                    min-height: 48rem;

                    h2 {
                        font-size: 3rem;
                        text-align: center;
                    }

                    p {
                        font-size: 2.2rem;
                        text-align: center;
                    }
                }
            }
        }
    }

    .illustration {
        width: 100%;
        display: block;
        margin-bottom: -10rem;

        img {
            display: block;
            width: 100%;
            height: auto;

        }
    }

}



@media (max-width:425px) {
    #exportAndImport {
        .main_body {
            #desktop {
                display: none;
            }

            #moblie {
                padding: 0 15% !important;
                display: block !important;
            }

            h2 {
                font-size: 3rem;
                text-align: center;
            }

            section{
                p{
                    text-align: center;
                }
            }


            .singleStep{
                width: 100% !important;
                margin-bottom: 2.5rem;
            }
        }
    }
}