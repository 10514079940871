#contact {
    .main_body {
        padding-top: 10rem;


        .wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 10rem;
            flex-direction: row-reverse;

            h2 {
                margin-bottom: 4.5rem;
                color: #00609D;
                font-weight: 400;
                font-size: 5rem;
            }

            .left {
                max-width: 42rem;

                p {
                    font-size: 2.5rem;
                    color: rgba(0, 0, 0, 0.7);
                }

                .address {
                    margin-top: 4rem;

                    p {
                        margin-bottom: 2rem;
                    }
                }
            }

            .right {
                width: 71rem;

                h2 {
                    margin-bottom: 5rem;
                }

                form {
                    width: 100%;

                    input {
                        display: block;
                        width: 100%;
                        background-color: #F0F0F0;
                        border: none;
                        outline: none;
                        margin-bottom: 2rem;
                        font-size: 1.8rem;
                        padding: 1.5rem;

                        &::placeholder {
                            color: rgba(0, 0, 0, 0.3);
                        }

                        &:focus {
                            border: none;
                            outline: none;
                        }
                    }

                    textarea {
                        width: 100%;
                        height: 30rem;
                        background-color: #F0F0F0;
                        padding: 1.5rem;
                        resize: none;
                        border: none;
                        outline: none;
                        font-size: 1.8rem;
                        margin-bottom: 3rem;

                        &::placeholder {
                            color: rgba(0, 0, 0, 0.3);
                        }
                    }

                    button {
                        background-color: #00609D;
                        padding: 1rem 5rem;
                        font-size: 2.5rem;
                        color: #ffffff;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        transition: .2s;
                        -webkit-transition: .2s;
                        -moz-transition: .2s;
                        -ms-transition: .2s;
                        -o-transition: .2s;

                        &:focus {
                            border: none;
                            outline: none;
                        }

                        &:hover{
                            background-color: #004b7a;
                        }
                    }
                }
            }

            @media (max-width:768px){
                .right{
                    margin-top: 4rem !important;
                }
            }
        }

        .map{
            width: 100%;

            iframe{
                width: 100%;
                height: 70rem;
            }
        }
    }
}