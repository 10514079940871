.addProductButton {
    background-color: rgb(0, 98, 159);
    text-decoration: none;
    padding: 1rem 2rem;
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 500;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    -o-transition: .2s;




    &:hover {
        background-color: rgb(3, 82, 131);
    }
}


.addCategoryPage {
    display: flex;
    justify-content: space-between;



    form {
        width: 40%;

        select {
            display: block;
            margin-bottom: 2rem;
            width: 100%;
            height: 5rem;
            outline: none;
            border: .1rem solid rgba(0, 98, 159, 0.4);
            font-size: 2.5rem;
            font-weight: 400;
            color: rgba(1, 50, 80, 0.5);
            padding: .5rem 0;


        }

        input {
            display: block;
            margin-bottom: 2rem;
            font-size: 2.5rem;
            font-weight: 400;
            padding: 1rem;
            outline: none;
            border: .1rem solid rgba(0, 98, 159, 0.4);
            color: rgb(0, 98, 159);
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;
            width: 100%;

            &:focus {
                outline: none;
                border: .1rem solid rgba(0, 98, 159, 1);

            }
        }

        input::placeholder {
            color: rgba(1, 50, 80, 0.5);
        }

        input[type="file"] {
            display: none;
        }

        label {
            background-color: rgb(0, 98, 159);
            font-size: 2.5rem;
            color: #ffffff;
            font-weight: 500;
            cursor: pointer;
            padding: 1rem 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;

            &:hover {
                background-color: rgb(3, 82, 131);
            }
        }

        .image_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;

            .category_thumbnail {
                width: 29rem;
                margin-left: 3rem;
            }
        }

        button {
            background-color: rgb(0, 98, 159);
            font-size: 2.5rem;
            color: #ffffff;
            font-weight: 500;
            cursor: pointer;
            padding: 1rem 2rem;
            display: block;
            justify-content: center;
            align-items: center;
            outline: none;
            border: none;
            width: 100%;
            margin-top: 2.5rem;
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;

            &:hover {
                background-color: rgb(3, 82, 131);
            }
        }
    }


    .read_board {
        width: 40%;
        color: rgb(3, 82, 131);
        font-size: 2.5rem;
        line-height: 1.7;

        p {
            background-color: rgba(3, 82, 131, 0.05);
            padding: 2rem;

        }
    }


}



.wrapper {
    margin-top: 5rem;
    width: 100%;

    table {
        width: 100%;

        thead {
            font-size: 2.5rem;
            background-color: rgba(0, 98, 159, 0.05);

            th {
                color: #00619F;
                padding: 1rem;
            }
        }

        tbody {
            tr {
                td {
                    text-align: center;
                    font-size: 2.3rem;
                    color: #00619F;
                    padding: 1rem;

                    img {
                        width: 10rem;
                    }

                    a {
                        text-decoration: none;
                        background-color: #00619F;
                        color: #ffffff;
                        transition: .2s;
                        -webkit-transition: .2s;
                        -moz-transition: .2s;
                        -ms-transition: .2s;
                        -o-transition: .2s;
                        padding: 1rem;
                        display: inline-block;
                        margin: 1rem;

                        
                        &:hover {
                            background-color: #003f66;
                        }




                    }




                    button {
                        background-color: rgb(231, 41, 41);
                        color: #ffffff;
                        transition: .2s;
                        -webkit-transition: .2s;
                        -moz-transition: .2s;
                        -ms-transition: .2s;
                        -o-transition: .2s;
                        padding: 1rem;
                        display: inline-block;
                        margin: 1rem;
                        outline: none;
                        border: none;
                        font-size: 2.3rem;
                        cursor: pointer;




                        &:hover {
                            background-color: rgb(202, 17, 17);
                        }

                    }

                }
            }
        }
    }
}