#services {
    .main_body {
        padding: 10rem 0;

        h2 {
            color: #00609D;
            font-size: 5rem;
            margin-bottom: 3rem;
        }

        section {
            margin-bottom: 10rem;

            p {
                max-width: 116rem;
                font-size: 2.5rem;
                color: rgba(0, 0, 0, 0.7);
                line-height: 1.6;
            }
        }

        .box_wrapper {
            width: 100%;
            margin-top: 15rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .box {
                width: 23%;
                background-color: #00609D;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: column;
                padding: 0 2rem 7rem 2rem;

                .crcl {
                    height: 20rem;
                    width: 20rem;
                    border-radius: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    -ms-border-radius: 100%;
                    -o-border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 5rem;
                    color: #00609D;
                    background-color: #fff;
                    margin-top: -10rem;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    margin-bottom: 4rem;
                }

                h3 {
                    color: #FFF200;
                    font-size: 3rem;
                    margin-bottom: 3rem;
                    text-align: center;
                    text-transform: uppercase;
                }

                p {
                    text-align: center;
                    font-size: 2.2rem;
                    color: #ffffff;
                    line-height: 1.4;
                }
            }

            .box.large{
                width: 31%;
            }
        }

        .large{
            margin-bottom: 15rem;
        }
    }
}



@media (max-width:768px){
    #services {
        .main_body {
            padding: 10rem 0;
    
            h2 {
                font-size: 4rem !important;
                margin-bottom: 3rem;
                text-align: center !important;
            }
    
            section {
                margin-bottom: 10rem;
    
                p {
                    max-width: 116rem;
                    font-size: 2.5rem;
                    color: rgba(0, 0, 0, 0.7);
                    line-height: 1.6;
                    text-align: center;
                }
            }
    
            .box_wrapper {
                width: 100%;
                margin-top: 15rem;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
    
                .box, .box.large {
                    width: 42%;
                    background-color: #00609D;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    flex-direction: column;
                    padding: 0 2rem 7rem 2rem;
                    margin-bottom: 20rem;
    
                    .crcl {
                        height: 20rem;
                        width: 20rem;
                        border-radius: 100%;
                        -webkit-border-radius: 100%;
                        -moz-border-radius: 100%;
                        -ms-border-radius: 100%;
                        -o-border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 4rem;
                        color: #00609D;
                        background-color: #fff;
                        margin-top: -10rem;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        margin-bottom: 4rem;
                    }
    
                    h3 {
                        color: #FFF200;
                        font-size: 3rem;
                        margin-bottom: 3rem;
                        text-align: center;
                    }
    
                    p {
                        text-align: center;
                        font-size: 2.2rem;
                        color: #ffffff;
                        line-height: 1.4;
                    
                    }
                }
            }

        }
    }
}


@media (max-width:425px){

    .box{
        width: 100% !important;
    }

}