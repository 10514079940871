#director {
    background-color: #fff;
    padding-top: 10rem;

    h2 {
        font-size: 5rem;
        color: rgba(0, 0, 0, 0.7);
        text-align: center;
        font-weight: 400;
        margin-bottom: 10rem;
    }


    .main {
        width: 100%;
        display: flex;
        justify-content: center;

        .wrapper {
            width: 109rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 12rem;

            .signleCard {
                width: 30%;
                height: 19rem;
                background-color: #00619F;


                .top {
                    margin-top: -10rem;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .image {
                        width: 20rem;
                        height: 20rem;
                        border-radius: 100%;
                        -webkit-border-radius: 100%;
                        -moz-border-radius: 100%;
                        -ms-border-radius: 100%;
                        -o-border-radius: 100%;
                        background-color: rgb(150, 150, 150);
                        overflow: hidden;
                        object-fit: cover;

                        img {
                            width: 100%;
                            height: 100%;
                        }

                    }
                }

                h2 {
                    margin-top: 2rem;
                    margin-bottom: 0;
                    color: #ffffff;
                    font-size: 2rem;
                }

                h3 {
                    text-align: center;
                    margin-bottom: 0;
                    color: #ffffff;
                    font-size: 1.8rem;

                }
            }
        }

    }




    @media (max-width:768px) {
        .main {
            .wrapper {
                width: 100% !important;
                margin-top: 0 !important;

                .signleCard {
                    width: 32%;
                    margin-top: 15rem;

                    .top{
                        .image{
                            height: 15rem !important;
                            width: 15rem !important;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:425px) {
        h2{
            font-size: 3.5rem;
        }
        .main {
            .wrapper {
                width: 100% !important;
                margin-top: 0 !important;

                .signleCard {
                    width: 45%;
                    height: 15rem !important;
                    margin-top: 15rem;
                }
            }
        }
    }
}