.adminContactWrapper {

    p {
        font-size: 2.5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 2rem;

        a{
            text-decoration: underline;
            color: black;
        }

        input {
            font-size: 2.5rem;
            display: inline-block;
            margin-left: 3rem;
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;
            width: 50%;
            padding: .5rem 1rem;
            outline: none;
            border: .1rem solid rgba(0, 98, 159, 0.15);

            &:focus{
                border: .1rem solid rgba(0, 98, 159, 0.5);
            }
            

}

        button {
            display: inline-block;
            margin-left: 3rem;
            font-size: 2.5rem;
            padding: 0.5rem 1.5rem;
            cursor: pointer;
            outline: none;
            border: none;
            background-color: rgb(0, 98, 159);
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;
            color: #ffffff;

            &:hover {
                background-color: rgb(3, 82, 131);
            }



        }
    }
}