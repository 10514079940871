#MenuBar {
    #largeDevice {
        background-color: #fff;

        .top {

            height: 11rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo {
                width: 12rem;
            }

            .GAQ {
                padding: 1rem 5.5rem;
                background-color: #00629F;
                font-size: 2rem;
                color: #ffffff;
                font-weight: 600;
                text-decoration: none;
                transition: .3s;
                -webkit-transition: .3s;
                -moz-transition: .3s;
                -ms-transition: .3s;
                -o-transition: .3s;

                &:hover {
                    background-color: rgb(0, 81, 131);
                    color: #ffffff;
                }
            }

            #hambargerIconWrapper {
                height: 3rem;
                width: 3rem;
                position: relative;
                display: none;

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    opacity: 0;
                    transition: .2s;
                    -webkit-transition: .2s;
                    -moz-transition: .2s;
                    -ms-transition: .2s;
                    -o-transition: .2s;
                    padding: 0 !important;
                }

                img.active {
                    opacity: 1;
                }
            }

            .searchBox {
                width: 55rem;
                height: 4rem;
                border-radius: 2rem;
                -webkit-border-radius: 2rem;
                -moz-border-radius: 2rem;
                -ms-border-radius: 2rem;
                -o-border-radius: 2rem;
                background-color: rgba(0, 0, 0, 0.05);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2rem;

                input {
                    width: 90%;
                    border: none;
                    outline: none;
                    background: none;
                    font-size: 1.5rem;
                    color: rgba(0, 0, 0, 0.7);

                    &:focus {
                        background: none;
                    }
                }

                ::-webkit-input-placeholder {
                    /* Edge */
                    color: rgba(0, 0, 0, 0.2);
                }

                :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: rgba(0, 0, 0, 0.2);
                }

                ::placeholder {
                    color: rgba(0, 0, 0, 0.2);
                }

                .search_icon {
                    width: 2rem;
                }
            }
        }

        .bottom {
            background-color: #00629F;
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;



            ul {
                list-style: none;
                display: flex;
                width: 90rem;
                justify-content: space-between;


                li {
                    color: rgba(255, 255, 255, 1);
                    text-decoration: none;
                    font-size: 1.8rem;
                    text-transform: uppercase;
                    transition: .3s;
                    -webkit-transition: .3s;
                    -moz-transition: .3s;
                    -ms-transition: .3s;
                    -o-transition: .3s;
                    cursor: pointer;
                    position: relative;
                    padding: 1.5rem;
                    display: inline-flex !important;
                    z-index: 1111;
                    font-weight: 700;

                    &:hover {
                        color: #fff200;

                        ul {
                            transform: scale(1);
                            -webkit-transform: scale(1);
                            -moz-transform: scale(1);
                            -ms-transform: scale(1);
                            -o-transform: scale(1);
                        }
                    }



                    ul {
                        position: absolute;
                        width: 18rem;
                        height: auto;
                        top: 100%;
                        display: block;
                        box-shadow: 0 0 50px rgba(0, 0, 0, 0.075);
                        transition: .3s;
                        -webkit-transition: .3s;
                        -moz-transition: .3s;
                        -ms-transition: .3s;
                        -o-transition: .3s;
                        transform: scale(0);
                        -webkit-transform: scale(0);
                        -moz-transform: scale(0);
                        -ms-transform: scale(0);
                        -o-transform: scale(0);
                        padding: .7rem;
                        background-color: rgb(255, 255, 255);
                        z-index: 1111;


                        li {
                            display: block;
                            margin-bottom: 1rem;
                            padding: 0.5rem;
                            font-size: 1.6rem;

                            a {
                                color: rgba(0, 0, 0, 0.7);
                                display: block;
                                font-weight: 600;

                                &:hover {
                                    color: #00629F;
                                }
                            }
                        }
                    }

                    ul.exceptional {
                        width: 30rem;
                    }

                    a {
                        color: rgb(255, 255, 255);
                        text-decoration: none;
                        font-size: 1.8rem;
                        text-transform: uppercase;
                        transition: .3s;
                        -webkit-transition: .3s;
                        -moz-transition: .3s;
                        -ms-transition: .3s;
                        -o-transition: .3s;

                        &:hover {
                            color: #fff200;
                        }
                    }
                }
            }
        }

        .sm_menu {
            opacity: 0;
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;
            display: none;
            visibility: hidden;
            position: relative;
        }


        .bottomForSmall {
            display: none;
        }

        // responsive
        @media (max-width:800px) {
            .searchBox {
                display: none !important;
            }

            .logo {
                width: 7rem !important;
            }

            .GAQ {
                padding: .5rem 2rem !important;
                font-size: 1.4rem !important;
            }
        }

        @media (max-width: 767px) {
            #hambargerIconWrapper {
                display: block !important;
            }

            .bottom {
                display: none !important;
            }

            .sm_menu {
                display: block;
                opacity: 0;
                position: fixed;
                height: calc(100vh - 8rem);
                width: 100%;
                top: 8rem;
                left: 0;
                right: 0;
                z-index: 9999999999999999999999;

                .wrapper {
                    height: 100%;
                    width: 100%;
                    background-color: rgb(0, 97, 159);
                    margin-top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    ul {
                        font-size: 4rem;
                        list-style: none;
                        text-transform: uppercase;

                        li {
                            color: #ffffff;
                            margin-bottom: 1.5rem !important;

                            a {
                                text-decoration: none;
                                color: #ffffff;
                            }

                            ul {
                                margin-left: 3rem;
                                opacity: .8;
                                font-size: 3rem;
                                margin-top: 1.5rem;
                                margin-bottom: 3rem;
                            }
                        }
                    }
                }
            }

            .sm_menu.active {
                display: block;
                opacity: 1;
                visibility: visible;
                z-index: 9999999999999999;
            }
        }

        @media (max-width:425px) {
            #hambargerIconWrapper {
                display: block;
            }

            .top {
                height: 8rem;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                background-color: #fff;
                z-index: 9999999999999999999;
            }
        }
    }
}