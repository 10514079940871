#our_client {
    padding-top: 12rem !important;
    background-color: #fff;

    h2 {
        font-size: 5rem;
        color: rgba(0, 0, 0, 0.8);
        text-align: center;
        font-weight: 400;
        margin-bottom: 6rem;
    }

    img{
        width: 100%;
    }
}