#lifeStyleAndIndustries {
    background-color: rgb(255, 255, 255);


    h1 {
        text-align: center;
        margin-bottom: 12rem;
        font-size: 7rem;
        color: #00609D;
        font-weight: 400;
        position: relative;
        left: 0;
        animation: anm5 2s linear;
        -webkit-animation: anm5 1s linear;

        opacity: 1;


        @keyframes anm5 {
            0% {
                left: -100%;
                opacity: 0;
            }

            100% {
                left: 0%;
                opacity: 1;
            }
        }
}


    section {
        margin-bottom: 5rem;

        h2 {
            font-size: 5rem;
            color: #00609D;
            margin-bottom: 3.5rem;
            font-weight: 400;
        }

        p {
            max-width: 112.5rem;
            font-size: 2.5rem;
            color: rgba(0, 0, 0, 0.7);
            text-align: justify;

        }


        .bulletWrapper {
            .single {
                height: 3rem;
                display: flex;
                align-items: center;
                margin-bottom: 1.5rem;

                span {
                    height: 2rem;
                    width: 2rem;
                    background-color: #00609D;
                    border-radius: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    -ms-border-radius: 100%;
                    -o-border-radius: 100%;
                    display: inline-flex;
                    margin-right: 2rem;
                }
            }
        }
    }


    #imageWrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }



        .top {
            width: 100%;
            height: 68rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 1rem;


            .left,
            .right {
                width: 49.5%;
                height: 100%;
                background-color: #00609D;
            }

        }

        .bottom {
            margin-top: 0.5rem;
            height: 60rem;
            width: 100%;
            background-color: #00609D;

        }
    }
}


@media (max-width:576px) {
    #lifeStyleAndIndustries {
        h1 {
            text-align: center;
            margin-bottom: 5rem;
            font-size: 4rem;
            color: #00609D;
            font-weight: 400;
        }

        section {
            margin-bottom: 3rem;

            h2 {
                font-size: 4rem;
                color: #00609D;
                margin-bottom: 2rem;
                font-weight: 400;
            }
        }

        #imageWrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }



            .top {
                width: 100%;
                height: 35rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;


                .left,
                .right {
                    width: 49.2%;
                    height: 100%;
                    background-color: #00609D;


                }

                .middle {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 0 0.5rem;
                    background: none;


                    .TOP {
                        margin: 0;
                        height: 49%;
                        background-color: #00609D;
                    }

                    .BOTTOM {
                        margin: 0;
                        height: 49%;
                        background-color: #00609D;
                    }
                }
            }

            .bottom {
                margin-top: 0;
                height: 30rem;
                width: 100%;
                background-color: #00609D;

            }
        }
    }
}