#bannerslider {
    width: 100%;
    height: 100%;

    .slick-slider {
        height: 100%;
        width: 100%;

        .slick-list {
            height: 100%;
            width: 100%;

            .slick-track {
                .slick-slide {
                    height: 100%;
                    width: 100%;

                    div {
                        height: 100%;
                        width: 100%;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .slick-dots {
            bottom: -15px;

            li {
                margin: 0;

            }
        }

    }
}