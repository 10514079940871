#category {
    text-align: center;
    .main_body {
        padding-top: 10rem;

        h2 {
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
            font-size: 5rem;
            margin-bottom: 10rem;
            font-weight: 400;
        }


        .wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            text-align: center;





            .singleCard {
                width: 31%;
                margin-bottom: 10rem;

                img {
                    width: 100%;
                    display: block;
                    margin-bottom: 2rem;
                }

                h2 {
                    text-align: center;
                    color: rgba(0, 0, 0, 0.7);
                    font-size: 3rem;
                    margin-bottom: 1.7rem;
                    font-weight: 400;
                }

                h4{
                    color: #000000;
                    font-size: 2.5rem;
                    font-weight: 700;
                    margin-bottom: .5rem;
                    text-align: center;

                }

                p{
                    color: #000000;
                    font-size: 2.3rem;
                    margin-bottom: 2rem;
                    text-align: center;
                    font-weight: 400;
                }

                h3{
                    color: #00619D;
                    font-size: 2.8rem;
                    margin-top: 1.5rem;
                    text-align: center;
                }
            }
        }
    }


    @media (max-width:425px){
        h2{
            font-size: 3.5rem !important;
            margin-bottom: 5rem !important;
        }

        .singleCard{
            width: 100% !important;
            margin-bottom: 5rem !important;

            img{
                padding: 3rem !important;
                margin-bottom: 0 !important;
            }

            h2{
                margin-bottom: 2rem !important;
                text-align: center !important;
            }

            h3,h4,p{
                text-align: center !important;
            }
        }

    }
}