#new_arrival {
    padding-top: 10rem;
    width: 100%;

    h2 {
        font-size: 5rem;
        text-align: center;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.7);
    }

    p {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 10rem;
        color: rgba(0, 0, 0, 0.7);
    }


    .wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .arrow {
            cursor: pointer;
            width: 3rem;
            height: auto;
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;
            opacity: .2;

            &:hover {
                opacity: .5;
            }
        }

        .slider_main {
            height: 47rem;
            width: 100%;


            .singleSliderItemWrapper {
                height: 47rem;
                padding: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                outline: none;
                border: none;

                &:focus {
                    outline: none;
                    border: none;
                }

                .singleSliderItem {
                    height: 100%;
                    width: 100%;

                    .image {
                        height: 37rem;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 3rem;
                        


                        img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .wrapper{
            .arrow{
                display: none;
            }
        }
    }


}