#admin_message {
    table {
        width: 100%;

        thead {
            background-color: rgba(0, 98, 159, 0.05);
            font-size: 3rem;

            th {
                padding: 1rem;
                color: rgb(0, 98, 159);
            }

            .serial {
                width: 6%;
            }

            .action {
                width: 12%;
            }
        }

        tbody {
            font-size: 2.5rem;

            tr {
                border-bottom: .1rem solid rgb(0, 98, 159);

                td {
                    text-align: center;
                    padding: 0.5rem;
                    color: rgba(0, 0, 0, 0.8);

                    a {
                        display: inline-block;
                        margin: .5rem;
                        background-color: rgb(0, 98, 159);
                        color: #ffffff;
                        font-size: 2rem;
                        padding: 1rem;
                        cursor: pointer;
                        text-decoration: none;
                        transition: .2s;
                        -webkit-transition: .2s;
                        -moz-transition: .2s;
                        -ms-transition: .2s;
                        -o-transition: .2s;

                        &:hover {
                            background-color: rgb(0, 60, 97);
                        }
                    }

                    button {
                        display: inline-block;
                        margin: .5rem;
                        background-color: rgb(218, 2, 2);
                        color: #ffffff;
                        font-size: 2rem;
                        padding: 1rem;
                        cursor: pointer;
                        border: none;
                        outline: none;
                        transition: .2s;
                        -webkit-transition: .2s;
                        -moz-transition: .2s;
                        -ms-transition: .2s;
                        -o-transition: .2s;



                        &:hover {
                            background-color: rgb(148, 0, 0);

                        }
                    }
                }

                td.noDataExists{
                    color: rgb(0, 98, 159);
                }
            }

        }
    }
}





.singleMessageView {
    display: flex;
    justify-content: space-between;

    .left {
        width: 50%;
        font-size: 2.5rem;

        p {
            margin-bottom: 0.5rem;
            color: rgb(0, 60, 97);

        }

        p.messageBody {
            background-color: rgba(0, 60, 97, 0.05);
            margin-top: 1rem;
            padding: 2rem;
        }
    }

    .right {
        .deleteMessageButton {
            background-color: rgb(231, 41, 41);
            padding: 1rem 2rem;
            outline: none;
            border: none;
            transition: .2s;
            -webkit-transition: .2s;
            -moz-transition: .2s;
            -ms-transition: .2s;
            -o-transition: .2s;
            font-size: 2.5rem;
            color: #ffffff;
            cursor: pointer;


            &:hover {
                background-color: rgb(202, 17, 17);
            }
        }
    }


}